import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import {
  VideoSectionReversed,
  Jobs,
  Subscription,
} from 'components/windrate';

import pagedata from "content/pages/careers.yaml";
import subscription from "content/global/subscription.yaml";

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  promoSection: {
    background: theme.palette.alternate.main,
    padding: theme.spacing(6, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(12, 2),
    },
  },
  sectionAlternate2: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 40%, ${theme.palette.primary.dark} 0%)`,
  },   
}));

const Careers = () => {
  const classes = useStyles();

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
      <Divider />  
      </Section>      
      <SectionAlternate>
        <VideoSectionReversed data={pagedata.about} />
      </SectionAlternate>
      <Section className={classes.sectionNoPaddingTop}>
        <Jobs data={pagedata.jobs} />
      </Section>
      <SectionAlternate innerNarrowed className={classes.sectionAlternate2}>
          <Subscription data={subscription} />
      </SectionAlternate>
    </div>
  );
};

export default Careers;
